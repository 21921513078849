import React, { Suspense, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Spin } from 'antd';

import Header from '../organisms/components/Header';
import { routePortals } from '../../routes/PortalRoute';


export type PropsLayout = {
    children: React.ReactNode;
};
const LayoutPage = ({ children }: any) => {
    const Navigate = useNavigate()
    useEffect(() => {
        const tokenString = localStorage.getItem("token");
        if (tokenString == "" || tokenString == null) {
            Navigate(routePortals.HOME);
        }

    }, [])

    return (
        <div className='bg-[#F0F8FF] min-h-[100vh] '>
            <Header />
            <div className='  mt-10 '>

                <Suspense fallback={<Spin size="large" className='w-full h-[80vh] flex items-center justify-center  ' />} >
                    {children}
                </Suspense>

            </div>
        </div>
    )
}
export default LayoutPage
import { Dropdown } from "antd";
import React, { useState } from "react";
import user from "./../../../assets/icon/user.svg"
import logout from "./../../../assets/icon/logout.svg"
import changePassword from "./../../../assets/icon/changePassword.svg"
import logoutHover from "./../../../assets/icon/logoutHover.svg"
import changePasswordHover from "./../../../assets/icon/changePasswordHover.svg"
import ModalChangePasswordAuth from "./Modal/ModalChangePasswordAuth";
import { TOKEN, token } from "../../../configs/localStorage";
import { logoutApi } from "../../../apis/apis";
import { Notification } from "../../../configs/notification";
import ModalChangeInfoAuth from "./Modal/ModalChangeInfoAuth";
// import { setRole } from "../../../redux/slice/reduxGlobal";
const Auth = () => {
    const [openModalChangePasswordAuth, setOpenModalChangePasswordAuth] = useState(false)
    const [openModalChangeInfoAuth, setOpenModalChangeInfoAuth] = useState(false)
    const OpenModalChangePassword = () => {
        setOpenModalChangePasswordAuth(true)
    }
    const handleLogout = async () => {
        try {
            const formData = new FormData()

            formData.append("token", token)

            const res = await logoutApi(formData)
            if (res.data.status === 0) {
                Notification("success", res?.data?.message)
                setTimeout(() => {
                    localStorage.clear()
                    window.location.reload()
                }, 1000)
            } else {
                Notification("error", res?.data?.message)
            }

        } catch (e) {

        }

    }
    const items = [
        {
            key: '12',
            label: (
                <div onClick={() => setOpenModalChangeInfoAuth(true)} className=" change_info flex gap-2 items-center text-[20px] hover:text-[#72BB0D]  py-1 ">
                    <svg fill="#fff" className="w-[18px] h-[18px]  " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                    <span>Thông tin tài khoản</span>
                </div>
            ),
        },
        {
            key: '11',
            label: (
                <div onClick={OpenModalChangePassword} className=" change_password flex gap-2 text-[20px] hover:text-[#72BB0D]  py-1 ">
                    <img className="change_password-icon" src={changePassword} alt="" />
                    <img className="change_password-icon-hover" src={changePasswordHover} alt="" />
                    <span>Đổi mật khẩu</span>
                </div>
            ),
        },


        {
            key: '10',
            label: (
                <div onClick={handleLogout} className=" logout flex gap-2 text-[20px] hover:text-[#72BB0D] py-1 ">
                    <img className="logout-icon" src={logout} alt="" />
                    <img className="logout-icon-hover" src={logoutHover} alt="" />
                    <span>Đăng xuất</span>
                </div>
            ),
        },
    ]
    return (
        <>
            <Dropdown
                trigger={['click']}
                menu={{ items }}
                placement="bottomLeft"
                overlayClassName="auth-menu"
                className="cursor-pointer"
            >
                <div className="flex items-center gap-2 h-full">
                    <span>
                        Admin
                    </span>
                    <img src={user} alt="" />

                </div>

            </Dropdown>
            <ModalChangePasswordAuth openModalChangePasswordAuth={openModalChangePasswordAuth} setOpenModalChangePasswordAuth={setOpenModalChangePasswordAuth} />
            <ModalChangeInfoAuth openModalChangeInfoAuth={openModalChangeInfoAuth} setOpenModalChangeInfoAuth={setOpenModalChangeInfoAuth} />
        </>
    )
}
export default Auth
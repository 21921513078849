import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../../apis/apis";
import { Notification } from "../../../../configs/notification";

const ModalChangePasswordAuth = ({ openModalChangePasswordAuth, setOpenModalChangePasswordAuth }: any) => {
    const [form] = Form.useForm();

    const navigate = useNavigate()

    const onFinish = async (value: any) => {
        try {
            const formData = new FormData()
            const user = localStorage.getItem("user") || ""
            formData.append("account", user)
            formData.append("oldPass", value?.password)
            formData.append("newPass", value?.newPassword)
            const res = await changePassword(formData)
            if (res?.data?.status === 0) {
                Notification("success", res?.data?.message)
                form.resetFields()
                setOpenModalChangePasswordAuth(false)
            } else {
                Notification("error", res?.data?.message)
            }
        } catch (e) {

        }
    }

    return (
        <Modal
            centered
            open={openModalChangePasswordAuth}
            onOk={() => setOpenModalChangePasswordAuth(false)}
            onCancel={() => {
                setOpenModalChangePasswordAuth(false)
                form.resetFields()
            }}
            width={470}
            footer={false}
            className="modal_login"
        >
            <h2 className="mb-2">Đổi mật khẩu</h2>
            <Form
                form={form}

                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    label="Nhập mật khẩu hiện tại"
                    name="password"
                    className="text-[16px] input-number"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại !' }]}
                >
                    <Input className="h-[50px] text-[#646464] " placeholder="mật khẩu hiện tại " />
                </Form.Item>

                <Form.Item
                    label="Nhập mật khẩu mới "
                    name="newPassword"
                    className="text-[16px]"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới !' }]}
                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="Mật khẩu mới " />
                </Form.Item>
                <Form.Item
                    label="xác nhận  mật khẩu"
                    name="ConfirmPassword"
                    dependencies={['newPassword']}
                    hasFeedback
                    className="text-[16px] border-[#0D72BB]"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập xác nhận mật khẩu !',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu của bạn không trùng khớp!'));
                            },
                        }),
                    ]}

                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="Mật khẩu xác nhận " />
                </Form.Item>

                <Form.Item className="flex justify-center mt-5" >
                    <Button className="bg-[#0D72BB] h-[53px] text-[16px] rounded-[10px]  text-[#fff] w-[250px] " htmlType="submit">
                        Đổi mật khẩu
                    </Button>
                </Form.Item>
            </Form >
        </Modal >
    )
}
export default ModalChangePasswordAuth
import { instanceAxios } from "../configs/axios";

export const login = (body: any) => instanceAxios.post("api/login", body)
export const logoutApi = (body: any) => instanceAxios.post("api/logout", body)
export const changePassword = (body: any) => instanceAxios.post("api/change-password", body)
export const UpdateInfoAuth = (body: any) => instanceAxios.post("api/update-infor-account", body)
export const getInfoAuth = (body: any) => instanceAxios.post("api/get-infor-account", body)


export const sendCodeEmail = (body: any) => instanceAxios.post("api/send-code-change-pass", body)
export const forgotPassword = (body: any) => instanceAxios.post("api/update-forgotten-password", body)
export const GetListParking = (body: any) => instanceAxios.post("api/read-list-parking", body)
export const CreateParking = (body: any) => instanceAxios.post("api/create-parking", body)
export const UpdateParking = (body: any) => instanceAxios.post("api/update-parking", body)
export const UpdateParkingStatus = (body: any) => instanceAxios.post("api/parking-control", body)
export const DeleteParking = (body: any) => instanceAxios.post("api/delete-parking", body)
export const RandomSid = (body: any) => instanceAxios.post("api/create-sid", body)
export const RandomToken = (body: any) => instanceAxios.post("api/create-token", body)

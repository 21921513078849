import React, { lazy } from 'react'
const HomePage = React.lazy(() => import('../components/organisms/pages/Home'))
const QuanLyPage = React.lazy(() => import('../components/organisms/pages/QuanLy'))
export const routePortals = {
    HOME: '/',
    QUANLY: "/quan-ly-bai-do-xe"


}
export const routePortal = [
    {
        id: 1,
        route: routePortals.HOME,
        component: HomePage,
        permission: 'dashboard'
    },
    {
        id: 2,
        route: routePortals.QUANLY,
        component: QuanLyPage,
        permission: 'dashboard'
    }
]
import React, { useEffect, useState } from "react";
import Auth from "./Auth";
import { token } from "../../../configs/localStorage";

const Header = () => {

    return (
        <div className=" ">

            <div className="bg-[#0D72BB]">
                <div className="container">
                    <div className="flex justify-between items-center  h-[40px] text-[20px] text-[#fff] ">
                        <div className=" flex-1 flex justify-center" >
                            <div className="text-[#72BB0D] text-[20px] ">Quản lý bãi xe</div>
                        </div>
                        {
                            token
                            &&
                            <Auth />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import { UpdateInfoAuth, changePassword, getInfoAuth } from "../../../../apis/apis";
import { token } from "../../../../configs/localStorage";
import { Notification } from "../../../../configs/notification";

const ModalChangeInfoAuth = ({ openModalChangeInfoAuth, setOpenModalChangeInfoAuth }: any) => {
    const [form] = Form.useForm();

    const navigate = useNavigate()
    const getInfo = async () => {
        try {
            const formData = new FormData()

            formData.append("token", token)
            formData.append("account", localStorage.getItem("user") || "")
            const res = await getInfoAuth(formData)
            if (res?.data?.status == 1) {
                form.setFieldsValue({
                    email: res?.data?.message?.Email,
                    account: res?.data?.message?.Account,
                    name: res?.data?.message?.Name,
                })
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        getInfo()
    }, [])
    const onFinish = async (value: any) => {
        try {
            const formData = new FormData()

            formData.append("token", token)
            formData.append("email", value.email)
            formData.append("name", value.name)
            formData.append("account", localStorage.getItem("user") || "")
            const res = await UpdateInfoAuth(formData)
            if (res?.data?.status == 0) {
                Notification("success", res?.data?.message)
                setOpenModalChangeInfoAuth(false)
            } else {

                Notification("error", res?.data?.message)

            }
        } catch (e) {

        }
    }

    return (
        <Modal
            centered
            open={openModalChangeInfoAuth}
            onOk={() => setOpenModalChangeInfoAuth(false)}
            onCancel={() => setOpenModalChangeInfoAuth(false)}
            width={553}
            footer={false}
            className="modal_login"
        >
            <h2 className="mb-2">Thông tin tài khoản </h2>
            <Form
                form={form}

                onFinish={onFinish}
                layout="vertical"
            >



                <Form.Item
                    label="Tài khoản"

                    className="text-[16px] input-number"

                >
                    <Input disabled={true} value={localStorage.getItem("user") || ""} className="h-[50px] text-[#646464] " placeholder="Tài khoản " />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    className="text-[16px] input-number"

                >
                    <Input className="h-[50px] text-[#646464] " placeholder="Mật khẩu  " />
                </Form.Item>
                <Form.Item
                    label="Tên người dùng"
                    name="name"
                    className="text-[16px] input-number"

                >
                    <Input className="h-[50px] text-[#646464] " placeholder="Tên người dùng" />
                </Form.Item>


                <Form.Item className="flex justify-center mt-5" >
                    <Button className="bg-[#0D72BB] h-[53px] text-[16px] rounded-[10px]  text-[#fff] w-[250px] " htmlType="submit">
                        Lưu
                    </Button>
                </Form.Item>
            </Form >
        </Modal >
    )
}
export default ModalChangeInfoAuth